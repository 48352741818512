import React, { useContext, useEffect } from 'react';
import { PaymentContext } from '../Context/PaymentContext';
import remshia from '../../assets/images/ramshea (1).png'
import { useQuery } from 'react-query';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup'
import baseURL from '../../assets/images/config';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

function Address() {
  const token = localStorage.getItem('userToken');
  const {t} = useTranslation('global')
  let { setUserAddress, userAddress } = useContext(PaymentContext);
  let navigate = useNavigate()
  function checkAddress() {
    if (!userAddress) {
      toast.error('Please select address first');
    } else {
      navigate('/payment-session/shipping')
    }
  }

  let validationSchema = yup.object({
    phone: yup.string().required(t('errors.required')).matches(/^(0\d{10}|\d{11})$/, t('errors.phone')),
    address: yup.string().required(t('errors.required')),
    title: yup.string().required(t('errors.required')),
    city: yup.string().required(t('errors.required')),
    government: yup.string().required(t('errors.required')),
  })
  async function addAddress(values) {
    let { data } = await axios.post(`${baseURL}/user/add/address`, values, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
    toast.success(data.message)
  }

  let formik = useFormik({
    initialValues: {
      phone: 0,
      address: '',
      title: '',
      city: '',
      government: ''
    }, validationSchema,
    onSubmit: addAddress
  })


  async function getAddress() {
    return await axios.get(`${baseURL}/user/show/address`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
  }
  let { data, isLoading } = useQuery('address', getAddress, {
    refetchInterval: 1000
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Select Address</title>
      </Helmet>
      <Toaster />
      {isLoading ? (
        <section className="loading text-center d-flex align-items-center justify-content-center">
          <div>
            <div>
              <img src={remshia} alt="logo" />
            </div>
            <div className="loader mx-auto mt-4"></div>
          </div>
        </section>
      ) : (
        <section className="min-vh-100">
          {data?.data.data.address.map((addres) => (
            <div key={addres.id} className="row mb-4">
              <div className="col-lg-6 col-md-10">
                <div className="address pb-4">
                  <div className="address-header d-flex align-items-center justify-content-between">
                    <div className="address-title d-flex align-items-center">
                      <input
                        type="radio"
                        onChange={() => setUserAddress(addres.id)}
                        name="address"
                        value={addres.id}
                        id={`address-${addres.id}`}
                        className="mx-2"
                      />
                      <label
                        htmlFor={`address-${addres.id}`}
                        className="mb-0 main-font main-color fw-bold"
                      >
                        {addres.title}
                      </label>
                    </div>
                  </div>
                  <div className="address-body py-4">
                    <p className="main-font">{addres.address}</p>
                  </div>
                  <div className="address-footer">
                    <p className="mb-0 main-font"> {addres.phone}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="add-new d-flex align-items-center pt-5">
            <p
              className="mb-0 main-color main-font cursor-pointer"
              data-bs-toggle="modal"
              data-bs-target="#addAddress"
            >
              <i className="fa-solid fa-plus main-color me-3"></i>{t('custom_text.addNA')}
            </p>
          </div>
          <div
            className="modal fade"
            id="addAddress"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-body p-0 text-center">
                <form onSubmit={formik.handleSubmit}>
                  <div className="user-info p-2">
                    <div className="row g-4">
                      <div className="col-6">
                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} type="text" placeholder={t('placeholders.title')} name='title' className='w-100 p-3 sign-text' />
                        {formik.touched.title && formik.errors.title ? <p className="text-danger">{formik.errors.title}</p> : null}
                      </div>
                      <div className="col-6">
                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} type="text" placeholder={t('placeholders.city')} name='city' className='w-100 p-3 sign-text' />
                        {formik.touched.city && formik.errors.city ? <p className="text-danger">{formik.errors.city}</p> : null}
                      </div>
                      <div className="col-6">
                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} type="text" placeholder={t('placeholders.gov')} name='government' className='w-100 p-3 sign-text' />
                        {formik.touched.government && formik.errors.government ? <p className="text-danger">{formik.errors.government}</p> : null}
                      </div>
                      <div className="col-6">
                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} type="string" placeholder={t('placeholders.phonenumber')} name='phone' className='w-100 p-3 sign-text' />
                        {formik.touched.phone && formik.errors.phone ? <p className="text-danger">{formik.errors.phone}</p> : null}
                      </div>
                      <div className="col-12 position-relative">
                        <input onChange={formik.handleChange} onBlur={formik.handleBlur} type="text" placeholder={t('placeholders.addressDetail')} name='address' className='w-100 p-3 sign-text' />
                        {formik.touched.address && formik.errors.address ? <p className="text-danger">{formik.errors.address}</p> : null}
                      </div>
                    </div>
                    <div className="viwe-all-btn py-4">
                      <button type='submit' className='main-btn'><p className='mb-0'>{t('custom_text.addNA')}</p></button>
                    </div>
                  </div>
                </form>
                </div>
              </div>
            </div>
          </div>
          <div className="viwe-all-btn py-4 text-end">
            <button onClick={checkAddress} className="main-btn">
              <p className="mb-0">{t('custom_text.next')}</p>
            </button>
          </div>
        </section>
      )}
    </>
  );
}

export default Address;