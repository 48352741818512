import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { ButtonContext } from '../Context/ButtonContext';

function PaymentProutectedRoute(props) {
  let {hasNavigated} = useContext(ButtonContext);
  if(hasNavigated){
    return props.children
  }else{
    return <Navigate to={'/'}/>
  }
  
}

export default PaymentProutectedRoute;