import axios from "axios";
import { createContext } from "react";
import toast from "react-hot-toast";
import baseURL from "../../assets/images/config";

export let WishlistContext = createContext();

export default function WishlistContextProvider(props) {
    const token = localStorage.getItem('userToken');
    function addToWishlist(productId) {
        return axios.post(`${baseURL}/fav/add/${productId}`, null, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => response)
            .catch((err) => err);

    }
    function removeFromWishlist(productId) {
        return axios.get(`${baseURL}/fav/remove/${productId}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => response)
            .catch((err) => err);

    }
    return <WishlistContext.Provider value={{ addToWishlist, removeFromWishlist }}>
        {props.children}
    </WishlistContext.Provider>
}