import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/ramshea (1).png';
import footer from '../../assets/images/Untitled-1 2.png';
import { Link } from 'react-router-dom';
import axios from 'axios';
import baseURL from '../../assets/images/config';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation('global');
  const [footerData, setFooterData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchFooterData = async () => {
    try {
      const response = await axios.get(`${baseURL}/products/bestseller` , {
        headers: {
          lang: localStorage.getItem('lang')
        }
      });
      setFooterData(response.data);
    } catch (err) {
      setError(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFooterData();
  }, []); // Empty dependency array means this effect runs once when the component mounts
  useEffect(() => {
    fetchFooterData();
  }, [localStorage.getItem('lang')]); // Empty dependency array means this effect runs once when the component mounts

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <footer className="py-4 position-relative overflow-hidden">
        <div className="container-fluid">
          <div className="row footer-content g-4">
            <div className="col-lg-10">
              <div className="row justify-content-between text-md-start text-center g-4">
                <div className="col-lg-6 col-12">
                  <div className="footer-logo">
                    <img src={logo} alt="Remshia" />
                    <p
                      className="text-white main-font mt-2"
                      dangerouslySetInnerHTML={{
                        __html: footerData?.data.footer.description,
                      }}
                    ></p>
                    <div className='position-relative z-3'>
                      <ul className="list-unstyled d-flex align-items-center gap-3 mt-4">
                        <li>
                          <a href={footerData?.data.linkfooter.link_twitter}>
                            <i className="fa-brands fa-tiktok fa-2x text-white"></i>
                          </a>
                        </li>
                        <li>
                          <a href={footerData?.data.linkfooter.link_instegram}>
                            <i className="fa-brands fa-instagram fa-2x text-white"></i>
                          </a>
                        </li>
                        <li>
                          <a href={footerData?.data.linkfooter.link_facebook}>
                            <i className="fa-brands fa-facebook-square fa-2x text-white"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="about-footer">
                    <p className="text-white main-font">{t('navlinks.company')}</p>
                    <ul className="list-unstyled about-links">
                      <li className="my-2">
                        <Link to={"/"} className="decoration-footer">
                          <p className="text-white main-font mb-0">{t('navlinks.home')}</p>
                        </Link>
                      </li>
                      <li className="my-2">
                        <Link to={"/products"} className="decoration-footer">
                          <p className="text-white main-font mb-0">{t('navlinks.products')}</p>
                        </Link>
                      </li>
                      <li className="my-2">
                        <Link to={"/offers"} className="decoration-footer">
                          <p className="text-white main-font mb-0">{t('navlinks.offers')}</p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="about-footer">
                    <p className="text-white main-font">{t('navlinks.company')}</p>
                    <ul className="list-unstyled about-links">
                      <li className="my-2">
                        <Link to={"/bestSeller"} className="decoration-footer">
                          <p className="text-white main-font mb-0">{t('navlinks.bestSeller')}</p>
                        </Link>
                      </li>
                      <li className="my-2">
                        <Link to={"/about-us"} className="decoration-footer">
                          <p className="text-white main-font mb-0">{t('navlinks.about_us')}</p>
                        </Link>
                      </li>
                      <li className="my-2">
                        <Link to={"/contact-us"} className="decoration-footer">
                          <p className="text-white main-font mb-0">{t('navlinks.contact_us')}</p>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="footer-brdr" />
        </div>
        <div className="position-absolute top-0 end-0 d-md-block d-none">
          <img src={footer} alt="Remshia" />
        </div>
        <p className="mb-0 text-center main-font text-white">
          All Rights Reserved. &copy; 2024
          <a
            href="https://www.allsafeeg.com/en"
            className="text-red mx-2 h6"
          >
            All Safe
          </a>
        </p>
      </footer>
    </>
  );
}

export default Footer;
