import { createContext, useState } from "react";

export const cartCountContext = createContext();

export default function CartCountContextProvider({children}){
    const [cartCount , setCartCount] = useState('0')

    return <cartCountContext.Provider value={{cartCount , setCartCount}}>
        {children}
    </cartCountContext.Provider>
}