import axios from "axios";
import { createContext, useContext } from "react";
import toast from "react-hot-toast";
import { cartCountContext } from "./cartCountContext";
import baseURL from "../../assets/images/config";
import { useNavigate } from "react-router-dom";

export let CartContext = createContext();
export default function CartContextProvider(props) {
    const token = localStorage.getItem('userToken');
    let{setCartCount} = useContext(cartCountContext)
    function addToCart(productId) {
        return axios.post(`${baseURL}/addToCard`, {
            product_id: productId
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => {
                setCartCount(response?.data.data.count);
                toast.success(response?.data?.message);
            })
            .catch((err) => {
                return err
            });
    }


    function showCart() {
        return axios.get(`${baseURL}/showCard`,{
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => response)
            .catch((err) => err);
    }
    return <CartContext.Provider value={{addToCart , showCart}}>
        {props.children}
    </CartContext.Provider>
}