import React, { useContext, useEffect, useState } from 'react';
import vod from '../../assets/images/th (5) 1.png'
import { PaymentContext } from '../Context/PaymentContext';
import remshia from '../../assets/images/ramshea (1).png'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { CartContext } from '../Context/CartContext';
import { cartCountContext } from '../Context/cartCountContext';
import baseURL from '../../assets/images/config';
import { Helmet } from 'react-helmet';
import { UesrContext } from '../Context/UserContext';
import { useTranslation } from 'react-i18next';

function Payment() {
  const {t} = useTranslation('global')
  let { userName, userPhone, userAddress, userShipping, userDate, cartId, cartPoints } = useContext(PaymentContext);
  let { userToken, setUserToken } = useContext(UesrContext);
  let [loading, setLoading] = useState(false);
  const [cartItems, setCartItems] = useState(null);
  let { setCartCount } = useContext(cartCountContext)
  let { showCart } = useContext(CartContext);
  let [payment, setPayment] = useState(null)
  const token = localStorage.getItem('userToken');
  let navigate = useNavigate()
  async function makeCheckout() {
    if (userName != null && userPhone != null && userAddress != null && userShipping != null && cartId != null && payment != null) {
      setLoading(true);
      let data = await axios.post(`${baseURL}/checkout`, {
        card_id: cartId,
        username: userName,
        phone: userPhone,
        address_id: userAddress,
        shiping: userShipping,
        date: userDate,
        payment: payment,
        point: cartPoints
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).catch((err) => {
        console.log(err);
        if (err?.response?.status == 401) {
          toast.error('Please Login First')
          localStorage.removeItem('userToken');
          setUserToken(null);
          navigate('/sign-in')
        } else {
          toast.error(err?.response?.data?.message)
        }
      })
      setLoading(false);
      if (data?.data?.status == 200) {
        setCartCount('0')
        localStorage.setItem('cart count', '0')
        navigate('/order-is-done')
      }
    } else {
      toast.error('please select payment method first');
    }
  }
  async function showCartItems() {
    setLoading(true);
    let { data } = await showCart();
    if (data) {
      setCartItems(data.data)
    }
    setLoading(false)
  }
  useEffect(() => {
    showCartItems()
    console.log(cartItems);
  }, [])

  return <>
    <Toaster />
    <Helmet>
      <meta charSet="utf-8" />
      <title>Confirm Order</title>
    </Helmet>
    {loading ? <section className='loading text-center d-flex align-items-center justify-content-center'>
      <div>
        <div>
          <img src={remshia} alt="logo" />
        </div>
        <div className="loader mx-auto mt-4"></div>
      </div>
    </section> :
      cartItems != null ?
        <section className=''>
          <h3>{t('custom_text.paymentMethod')}</h3>
          <div className="row">
            <div className="col-lg-7">
              <table className="shipping">
                <tbody>
                  <tr>
                    <td>
                      <div className="regular d-flex align-items-center justify-content-between">
                        <div className="form d-flex align-item-cetner">
                          <select onChange={(e) => setPayment(e.target.value)} className='form-select'>
                            <option value="" selected disabled>{t('custom_text.payAsk')}</option>
                            <option value="cach">{t('custom_text.vodCash')} {cartItems.phone}</option>
                            <option value="delivery" >{t('custom_text.dPay')}</option>
                          </select>
                          {/* <img src={vod} className='w-100' alt="" />
                      <p className='mb-0 mx-1'>0100200020</p> */}
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-lg-5">
              <div className="total-cart-price-info p-3">
                <h4 className='main-font'>{t('custom_text.orderSummery')}</h4>
                <div className="cart-price py-3 d-flex align-items-center justify-content-between">
                  <p>{t('custom_text.shippingType')}</p>
                  {userShipping === 'free'?<p>{t('custom_text.free')}</p>:<p>{userShipping}</p> }
                </div>
                <div className="cart-price py-3 d-flex align-items-center justify-content-between">
                  <p>{t('custom_text.shipping')}</p>
                  <p>{cartItems.shiping}</p>
                </div>
                <div className="cart-price py-3 d-flex align-items-center justify-content-between">
                  <p>{t('custom_text.price')}</p>
                  <p>{cartItems.summary.total}</p>
                </div>
                <div className="cart-price py-3 d-flex align-items-center justify-content-between">
                  <p>{t('custom_text.coupon')}</p>
                  <p>{cartItems.coupon}</p>
                </div>
                <hr />
                <div className="cart-price py-3 d-flex align-items-center justify-content-between">
                  <p>{t('custom_text.total')}</p>
                  <p>{cartItems.summary.totalAfterShiping} EGP</p>
                </div>
                {userShipping == "date" ? <div className="cart-price py-3 d-flex align-items-center justify-content-between">
                  <p>{t('custom_text.deliveryDate')}</p>
                  <p>{userDate}</p>
                </div> : <div className="cart-price py-3 d-flex align-items-center justify-content-between">
                  <p>{t('custom_text.deliveryDate')}</p>
                  <p>{cartItems.summary.Estimated_Delivery_by}</p>
                </div>}

                <div className="viwe-all-btn text-center py-4">
                  <button onClick={makeCheckout} className='main-btn w-100'><p className='mb-0'>{t('custom_text.chekout')}</p></button>
                </div>
              </div>
            </div>
          </div>
        </section> : ''}

  </>
}

export default Payment;