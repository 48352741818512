import { createContext, useState } from "react";

export let UesrContext = createContext()

export default function UesrContextProvider(props){
    const [userToken, setUserToken] = useState(null)
    
    return<UesrContext.Provider value={{userToken, setUserToken}}>
        {props.children}
    </UesrContext.Provider>
}