import { useContext, useState } from "react";
import { PaymentContext } from "../Context/PaymentContext";
import { useNavigate } from "react-router-dom";
import { ButtonContext } from "../Context/ButtonContext";
import axios from "axios";
import toast from "react-hot-toast";
import baseURL from "../../assets/images/config";
import { useTranslation } from "react-i18next";
import { TranslateContext } from "../Context/TranslateContext";

export default function CartSammery({
  cart,
  pointFn,
  submitProduct,
  pointsDone,
  dataFromProduct
}) {
  const { t } = useTranslation('global')
  const { handelChangeLang, arLang } = useContext(TranslateContext);
  const [coupon, setCoupon] = useState("");
  const token = localStorage.getItem("userToken");
  const [pointsBtn, setPointsBtn] = useState(false);
  const [pointsPrice, setPointsPrice] = useState(0);
  let navigate = useNavigate();
  let {
    setUserName,
    setUserPhone,
    setUserAddress,
    setUserShipping,
    setUserDate,
    setCartId,
    cartPoints,
    setCartPoints,
  } = useContext(PaymentContext);
  let { setHasNavigated } = useContext(ButtonContext);
  function clearStates(id) {
    setUserName(null);
    setUserPhone(null);
    setUserAddress(null);
    setUserShipping(null);
    setUserDate(null);
    setCartId(id);
    setHasNavigated(true);
    navigate("/payment-session/info");
  }
  const fn = (e) => {
    setCoupon(e.target.value);
  };
  async function seePoints() {
    let data = await axios
      .get(`${baseURL}/usepoint`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((err) => {
        if (err) {
          setPointsBtn(false);
        }
        if (err.response.data.message == "not can use point") {
          toast.error("You must reach at least 1000 poitns before using them");
        } else {
          toast.error(err.response.data.message);
        }
      });
    setPointsPrice(data?.data.data.price);
    if (data?.data?.data?.price) {
      setPointsBtn(true);
    }

  }
  async function cancelPoints() {
    let data = await axios
      .get(`${baseURL}/usepoint/cancel`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((err) => err);
  }
  return (
    <>
      <div className="total-cart-price-info p-3 sticky-lg-top">
        <div className="cart-header d-flex align-items-center justify-content-between">
          <h4 className="main-font">{t('custom_text.orderSummery')}</h4>
          <h6 className="main-font main-color">{t('custom_text.yourPoints')}: {cart?.points}</h6>
        </div>
        <hr />
        <div className="cart-price py-3 d-flex align-items-center justify-content-between">
          <p>{t('custom_text.price')}</p>
          <p>{cart?.summary?.total} EGP</p>
        </div>
        <div className="cart-price py-3 d-flex align-items-center justify-content-between">
          <p>{t('custom_text.priceAfterC')}</p>
          <p>{cart?.price_after_coupon} EGP</p>
        </div>
        <div className="cart-price py-3 d-flex align-items-center justify-content-between">
          <p>{t('custom_text.shipping')}</p>
          <p>{cart?.shiping} EGP</p>
        </div>
        <hr />
        <div className="cart-price py-3 d-flex align-items-center justify-content-between">
          <p>{t('custom_text.total')}</p>
          <p>{cart?.summary?.totalAfterShiping} EGP</p>
        </div>
        {cart?.coupon === "not there" ?
          <div className="coupon position-relative">
            <input
              type="text"
              className="coupon-field"
              placeholder={t('custom_text.couponCode')}
              onChange={(e) => fn(e)}
            />
            <i className={`fa-regular fa-money-bill-1 position-absolute top-50 ${arLang ? "end-0" : "start-0"} mx-2 translate-middle-y`} />
            <div className={`enter-coupon-btn position-absolute mx-3 top-50 ${arLang ? "start-0" : "end-0"} translate-middle-y`}>
              <button
                onClick={() =>
                  pointFn(cart?.summary?.total, cart?.card_id, coupon)
                }
                type="submit"
                className="main-btn"
              >
                <p className="mb-0">{t('custom_text.enter')}</p>
              </button>
            </div>
          </div>
          : <div className="cart-price py-3 d-flex align-items-center justify-content-between">
            <p>{t('custom_text.coupon')}</p>
            <p>{cart?.coupon}</p>
          </div>}

        <div className="coupon">
          <div className="enter-coupon-btn my-4">
            <button
              type="button"
              onClick={seePoints}
              data-bs-toggle="modal"
              data-bs-target="#usePoints"
              className="main-btn"
            >
              <p className="mb-0">{t('custom_text.usePoints')}</p>
            </button>
          </div>
        </div>
        <div
          className={`main-btn w-100 ${!submitProduct
            ? "text-center py-4"
            : "viwe-all-btn text-center py-4"
            }`}
        >
          <button
            disabled={!submitProduct}
            onClick={() => {
              clearStates(cart.card_id);
            }}
            className="main-btn w-100"
          >
            <p className="mb-0">{t('custom_text.proceed')}</p>
          </button>
          {!submitProduct && (
            <div className="alert alert-danger text-center mt-3">
              {t('custom_text.plsSelect')}
            </div>
          )}
        </div>
      </div>
      <div
        className="modal fade"
        id="usePoints"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="usePoints"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="modal-title fs-5" id="usePoints">
                {" "}
                {t('custom_text.priceAfterP')}
              </h2>
              <button
                type="button"
                className="btn-close"
                onClick={cancelPoints}
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {pointsPrice ? (
                <h3 className="main-color text-center fw-bold">
                  {pointsPrice} EGP
                </h3>
              ) : (
                <h3 className="main-color text-center fw-bold">0 EGP</h3>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={cancelPoints}
                data-bs-dismiss="modal"
              >
                {t('custom_text.close')}
              </button>
              {pointsBtn ? (
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={pointsDone}
                  data-bs-dismiss="modal"
                >
                  {t('custom_text.confirmPoints')}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
