import React, { useContext } from 'react';
import { UesrContext } from '../Context/UserContext';
import { Navigate } from 'react-router-dom';

function ProutectedRoute({children}) {
  let { userToken } = useContext(UesrContext);
  if(userToken){
    return children
  }else{
    return <Navigate to={'/'}/>
  }
}

export default ProutectedRoute;