import { useEffect, useState } from "react";
import CartProducts from "../cartProducts";
import CartSammery from "../cartSammery";
import { useContext } from "react";
import { CartContext } from "../Context/CartContext";
import { UesrContext } from "../Context/UserContext";
import { Toaster } from "react-hot-toast";
import axios from "axios";
import baseURL from "../../assets/images/config";
import toast from "react-hot-toast";
import { cartCountContext } from "../Context/cartCountContext";
import { Link } from "react-router-dom";
import noCart from "../../assets/images/7117861_3298064 1.png";
import remshia from "../../assets/images/ramshea (1).png";
import { PaymentContext } from "../Context/PaymentContext";
import { useTranslation } from "react-i18next";
export default function CartPage() {
  const { t } = useTranslation('global')
  let { showCart } = useContext(CartContext);
  let { userToken } = useContext(UesrContext);
  let { setCartCount } = useContext(cartCountContext);
  const [cartItems, setCartItems] = useState(null);
  const [displayNone, setDisplayNone] = useState(false);
  const [selectArr, setSelectArr] = useState(0);
  const [loading, setLoading] = useState(false);
  const { cartPoints, setCartPoints } = useContext(PaymentContext);
  const [dataFromProduct, setDataFromProduct] = useState('')

  const token = localStorage.getItem("userToken");
  async function updateCart(id, qty, cartId) {
    setLoading(true);
    if (qty == 0) {
      setDisplayNone(true);
    } else {
      let { data } = await axios
        .post(
          `${baseURL}/changeSummaryCard/${cartId}`,
          {
            product_id: id,
            qty,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .catch((err) => err);
      if (data) {
        setCartItems(data.data);
      }
      setDisplayNone(false);
    }
    setSelectArr(0);
    setLoading(false);
  }
  async function removeFromCart(id, qty) {
    setLoading(true);
    let { data } = await axios
      .post(
        `${baseURL}/remove`,
        {
          product_id: id,
          qty,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch((err) => err);
    if (data) {
      setCartItems(data?.data);
      setCartCount(data.data.card_count);
      localStorage.setItem("cart count", data.data.card_count);
    }
    setLoading(false);
  }
  async function postCoupon(price, id, coupon) {
    setLoading(true);
    let { data } = await axios
      .post(
        `${baseURL}/add/coupons/${id}`,
        {
          name: coupon,
          totalprice: price,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch((err) => toast.error(err.response.data.message));
    if (data?.message) {
      toast.success(data.message);
      setCartItems(data.data);
    }
    setLoading(false);
  }
  async function showCartItems() {
    setLoading(true);
    let { data } = await showCart();
    if (data) {
      setCartItems(data.data);
      if (data?.data?.points != 0) {
        localStorage.setItem('points', data?.data?.points)
      }
    }
    setLoading(false);
  }
  async function donePoints() {
    let { data } = await axios
      .get(`${baseURL}/usepoint/done`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((err) => err);
    if (data) {
      setCartPoints(localStorage.getItem('points'))
    }
    showCartItems();
  }
  useEffect(() => {
    showCartItems();
  }, []);
  useEffect(() => {
    const removeModalBackdrop = () => {
      const modalBackdrop = document.querySelector(".modal-backdrop");
      if (modalBackdrop) {
        modalBackdrop.remove();
      }
    };

    return () => {
      removeModalBackdrop();
    };
  }, []);
  const [submitProduct, setSubmitProduct] = useState(false);
  const [itemsProduct, setItemProduct] = useState({});
  useEffect(() => {
    // Check if all values in itemsProduct are valid
    const allPropertiesHaveValues = Object.values(itemsProduct).every(
      (value) => value !== null && value !== undefined && value !== ""
    );

    // Extract items with typesale_name "buy 2 get 1"
    const buy2Get1Items = cartItems?.product_in_card?.filter(
      (item) => item?.typesale_name?.toLowerCase() === "buy 2 get 1" && item.product_qty === 2
    );

    // Additional validation: dataFromProduct length should match cartItems.card_count
    const isDataFromProductValid =
      cartItems && dataFromProduct?.length === buy2Get1Items?.length;


    // Logging for debugging
    console.log('Filtered "buy 2 get 1" items: ', buy2Get1Items);
    console.log('Cart Items: ', buy2Get1Items?.length);
    console.log('Data From Product Length: ', dataFromProduct?.length);
    console.log('Submit Product: ', submitProduct);

    // Combine all conditions to determine if the button should be active
    setSubmitProduct(allPropertiesHaveValues && isDataFromProductValid);
  }, [itemsProduct, dataFromProduct, cartItems]);
  useEffect(() => {
    setCartPoints(0);
  }, [])

  const handleDataFromProduct = (data) => {

    setDataFromProduct(data)
  }
  return (
    <>
      <>
        {userToken ? (
          <>
            {loading ? (
              <section className="loading text-center d-flex align-items-center justify-content-center">
                <div>
                  <div>
                    <img src={remshia} alt="logo" />
                  </div>
                  <div className="loader mx-auto mt-4"></div>
                </div>
              </section>
            ) : (
              <>
                {cartItems != null ? (
                  <>
                    {cartItems?.product_in_card.length == 0 ? (
                      <section className="py-5 empty d-flex align-items-center justify-content-center">
                        <div>
                          <img src={noCart} className="w-100" alt="" />
                          <h2 className="text-center">{t('custom_text.emptyCart')}</h2>
                          <div className="viwe-all-btn text-center py-4">
                            <Link to={"/"}>
                              <button className="main-btn">
                                <p className="mb-0">{t('custom_text.returnHome')}</p>
                              </button>
                            </Link>
                          </div>
                        </div>
                      </section>
                    ) : (
                      <>
                        <Toaster />
                        <main className="p-5">
                          <div className="container">
                            <div className="row">
                              <div className="col-md-7">
                                <CartProducts
                                  dis={displayNone}
                                  cartId={cartItems?.card_id}
                                  update={updateCart}
                                  cart={cartItems?.product_in_card}
                                  gift={handleDataFromProduct}
                                  remove={removeFromCart}
                                  setSubmitProduct={setSubmitProduct}
                                  setItemProduct={setItemProduct}
                                  itemsProduct={itemsProduct}
                                />
                              </div>
                              <div className="col-md-5">
                                <CartSammery
                                  cart={cartItems}
                                  pointFn={postCoupon}
                                  pointsDone={donePoints}
                                  submitProduct={submitProduct}
                                  dataFromProduct={dataFromProduct}
                                />
                              </div>
                            </div>
                          </div>
                        </main>
                        \
                      </>
                    )}
                  </>
                ) : (
                  <section className="py-5 empty d-flex align-items-center justify-content-center">
                    <div>
                      <img src={noCart} className="w-100" alt="" />
                      <h2 className="text-center">{t('custom_text.emptyCart')}</h2>
                      <div className="viwe-all-btn text-center py-4">
                        <Link to={"/"}>
                          <button className="main-btn">
                            <p className="mb-0">{t('custom_text.returnHome')}</p>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </section>
                )}
              </>
            )}
          </>
        ) : (
          <section className="py-5 empty d-flex align-items-center justify-content-center">
            <div>
              <img src={noCart} className="w-100" alt="" />
              <h2 className="text-center">
                {t('custom_text.lFirst')}
              </h2>
              <div className="viwe-all-btn text-center py-4">
                <Link to={"/sign-in"}>
                  <button className="main-btn">
                    <p className="mb-0">{t('custom_text.lNow')}</p>
                  </button>
                </Link>
              </div>
            </div>
          </section>
        )}
      </>
    </>
  );
}
