import React, { useContext, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, FreeMode, Thumbs } from 'swiper/modules';
import remshia from '../../assets/images/ramshea (1).png'
import { Link, useNavigate, useParams } from 'react-router-dom';
import review1 from '../../assets/images/default-user-icon.jpg'
import Rating from '@mui/material/Rating';
import Avatar from '@mui/material/Avatar';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { CartContext } from '../Context/CartContext';
import { WishlistContext } from '../Context/WishlistContext';
import baseURL from '../../assets/images/config';
import { Helmet } from 'react-helmet';
import ReactImageMagnify from 'react-image-magnify';
import { UesrContext } from '../Context/UserContext';
import { useTranslation } from 'react-i18next';
function ProductDetails() {
  const {t} = useTranslation('global')
  let { id } = useParams();
  let [loading, setLoading] = useState(false);
  let [details, setDetails] = useState();
  let { addToWishlist } = useContext(WishlistContext);
  let { userToken, setUserToken } = useContext(UesrContext);
  async function postToWishlist(id) {
    let { data } = await addToWishlist(id);
    if (data) {
      toast.success(data.message);
    }
  }


  async function getDetails() {
    setLoading(true);
    let { data } = await axios.get(`${baseURL}/products/details/${id}`, {
      headers: {
        lang: localStorage.getItem('lang')
      }
    });
    setDetails(data.data);
    setLoading(false)
  }

  const copyLinkToClipboard = () => {
    const link = details.OverView_Product.link;
    if (link) {
      navigator.clipboard.writeText(link)
        .then(() => {
          toast('Link copied to clipboard!');
        })
        .catch((error) => {
          toast.error('Failed to copy link. Please try again.');
        });
    }
  }
  const [rating, setRating] = useState(0);
  const [opinion, setOpinion] = useState('');
  const token = localStorage.getItem('userToken');

  const handleRatingChange = (event, newValue) => {
    setRating(newValue);
  };

  const handleOpinionChange = (event) => {
    setOpinion(event.target.value);
  };

  let { addToCart } = useContext(CartContext);

  let navigate = useNavigate()
  async function postToCart(id) {
    let data = await addToCart(id);
    if (data?.response?.status == 401) {
      toast.error('Please Login First')
      localStorage.removeItem('userToken');
      setUserToken(null);
      navigate('/sign-in')
    }
  }
  async function addReview(id, comment, rate) {
    try {
      const { data } = await axios.post(
        `${baseURL}/review/send`,
        {
          product_id: id,
          comment: comment,
          rate: rate
        },
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      toast.success(data.message)
    } catch (error) {
      toast.error(error.response.data.message)
      // Handle error
    }
  }


  useEffect(() => {
    const removeModalBackdrop = () => {
      const modalBackdrop = document.querySelector('.modal-backdrop');
      if (modalBackdrop) {
        modalBackdrop.remove();
      }
    };

    return () => {
      removeModalBackdrop();
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    getDetails();
  }, [id, localStorage.getItem('lang')]);


  return (
    <>
      <Toaster />
      {loading ? (
        <section className="loading text-center d-flex align-items-center justify-content-center">
          <div>
            <div>
              <img src={remshia} alt="logo" />
            </div>
            <div className="loader mx-auto mt-4"></div>
          </div>
        </section>
      ) : (
        <>
          {details && (
            <>
              <Helmet>
                <meta charSet="utf-8" />
                <title>{details.OverView_Product.name}</title>
              </Helmet>
              <section className="py-5">
                <div className="container">
                  <div className="row align-items-center g-4">
                    <div className="col-lg-6">
                      <div>
                        <Swiper
                        dir='ltr'
                          style={{
                            "--swiper-navigation-color": "#000",
                            "--swiper-pagination-color": "#fff",
                          }}
                          spaceBetween={10}
                          navigation={true}
                          pagination={true}
                          modules={[FreeMode, Navigation, Thumbs, Pagination]}
                          className="mySwiper3"
                        >
                          <SwiperSlide >
                            <img src={details.OverView_Product.main_image} className='w-100' alt={details.OverView_Product.name} />
                          </SwiperSlide>
                          {details.OverView_Product.images.map((img) => (
                            <SwiperSlide key={img.product_image_id}>
                              <img src={img.product_image} alt='images' className='w-100' />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="product-details-content p-4">
                        <div className="product-details-info">
                          <div className="details-title">
                            <h3 className="main-font mb-0">
                              {details.OverView_Product.name}
                            </h3>
                            <p className="min-title"
                            dangerouslySetInnerHTML={{
                              __html: details.OverView_Product.overview,
                            }}
                          ></p>
                          </div>
                          <div className="details-price d-flex">
                            <p className="mb-0 new-price mx-1">
                              {details.OverView_Product.product_after_sale}EGP
                            </p>
                          </div>
                        </div>
                        <div className="viwe-all-btn py-4">
                          <button
                            onClick={() =>
                              postToCart(details.Product_details.product_id)
                            }
                            className="main-btn"
                          >
                            <p className="mb-0">{t('custom_text.addCart')}</p>
                          </button>
                        </div>
                        <div className="details-footer">
                          <div className="share d-flex align-items-center">
                            <p className="mb-0 me-2">{t('custom_text.share')}</p>
                            <i
                              className="fa-solid fa-share-nodes cursor-pointer"
                              onClick={copyLinkToClipboard}
                            ></i>
                            <div className="d-none">
                              {details.OverView_Product.link}
                            </div>
                          </div>
                          <h4 className='fw-bold my-2'>{t('custom_text.disc')}:</h4>
                          <p className="returns-title" dangerouslySetInnerHTML={{
                              __html: details.OverView_Product.description,
                            }}>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="py-5">
                <div className="main-title text-center py-5 p-fix">
                  <h2 className="mb-0">{t('custom_text.productDetails')}</h2>
                </div>
                <div className="container">
                  <table className="table details table-striped">
                    <tbody>
                      <tr>
                        <td>
                          <p className="mb-0 main-font">{t('custom_text.smell')}</p>
                        </td>
                        <td>
                          <p className="mb-0 main-font">
                            {details.Product_details.smell}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="mb-0 main-font">{t('custom_text.asin')}</p>
                        </td>
                        <td>
                          <p className="mb-0 main-font">
                            {details.Product_details.product_code}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="mb-0 main-font">{t('custom_text.itemModel')}</p>
                        </td>
                        <td>
                          <p className="mb-0 main-font">
                            {details.Product_details.model_number}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="mb-0 main-font">{t('custom_text.cat')}</p>
                        </td>
                        <td>
                          <p className="mb-0 main-font">
                            {details.Product_details.category_name}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="mb-0 main-font">{t('custom_text.reviews')}</p>
                        </td>
                        <td>
                          <p className="mb-0 main-font">
                            {details.Product_details.reviews}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
              <section className="py-5">
                <div className="container">
                  <div className="main-title text-center py-5 p-fix">
                    <h2 className="mb-0">{t('custom_text.howToUse')}</h2>
                  </div>
                  <div className="row g-4 align-items-center">
                    <div className="col-lg-6">
                      <div
                        className="how-to-use p-3"
                        dangerouslySetInnerHTML={{
                          __html: details.How_to_use.how_to_use,
                        }}
                      ></div>
                    </div>
                    <div className="col-lg-6">
                      <div className="how-to-use-img">
                        <Swiper
                        dir='ltr'
                          style={{
                            "--swiper-navigation-color": "#000",
                            "--swiper-pagination-color": "#fff",
                          }}
                          spaceBetween={10}
                          navigation={true}
                          pagination={true}
                          modules={[FreeMode, Navigation, Pagination]}
                          className="mySwiper2"
                        >
                          {details.How_to_use.images.map((img) => (
                            <SwiperSlide key={img.product_image_id}>
                              <img
                                src={img.product_image}
                                alt="how to use"
                                className="w-100"
                              />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="py-5">
                <div className="container">
                  <div className="main-title text-center py-5 p-fix">
                    <h2 className="mb-0">{t('custom_text.relatedProducts')}</h2>
                  </div>
                  <div className="section-info">
                    <div className="row">
                      <Swiper
                      dir='ltr'
                        slidesPerView={1}
                        spaceBetween={45}
                        pagination={{
                          clickable: true,
                        }}
                        breakpoints={{
                          640: {
                            slidesPerView: 1,
                            spaceBetween: 40,
                          },
                          768: {
                            slidesPerView: 2,
                            spaceBetween: 40,
                          },
                          1024: {
                            slidesPerView: 3,
                            spaceBetween: 50,
                          },
                        }}
                        className="mySwiper"
                        modules={[Pagination]}
                      >
                        {details.realted_product.map((product) => (
                          <SwiperSlide
                            key={product.id}
                            className="col-lg-4 col-md-6"
                          >
                            <div className="product position-relative p-4 text-center">
                              <Link
                                className="text-decoration-none"
                                to={`/products/product-details/${product.id}`}
                              >
                                <div>
                                  <div className="product-price position-absolute p-2 top-0 start-0">
                                    <p className="mb-0">{product.price}EGP</p>
                                  </div>

                                  <h4>{product.name}</h4>
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: product.overview
                                        .split(" ")
                                        .slice(0, 7)
                                        .join(" "),
                                    }}
                                  ></p>
                                  <div className="product-img">
                                    <img
                                      src={product.image}
                                      className="w-100"
                                      alt={product.name}
                                    />
                                  </div>
                                </div>
                              </Link>
                              <div
                                onClick={() => postToWishlist(product.id)}
                                className="product-heart position-absolute top-0 end-0 m-3"
                              >
                                <i className="fa-regular fa-heart fa-2x"></i>
                              </div>
                              <button
                                onClick={() => postToCart(product.id)}
                                className="my-4"
                              >
                                <p className="mb-0">{t('custom_text.addCart')}</p>
                              </button>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                  </div>
                </div>
              </section>
              <section className="py-5 mb-5">
                <div className="main-title text-center py-5 p-fix">
                  <h2 className="mb-0">{t('custom_text.reviews')}</h2>
                </div>
                <div className="container">
                  <div className="row g-5">
                    <div className="col-lg-4 col-md-6">
                      <div className="coustomer-reviews">
                        <div className="rating-stars d-flex align-items-center">
                          <Rating
                            name="half-rating-read"
                            defaultValue={parseFloat(
                              details.Product_details.reviews
                            )}
                            precision={0.5}
                            readOnly
                          />
                          <p className="mb-0 ms-2">
                            <span className="fw-bold">
                              {details.Product_details.reviews}
                            </span>
                            Out of <span className="fw-bold">5</span>
                          </p>
                        </div>
                        <div className="rating-progress">
                          <div className="five-stars d-flex align-items-center my-3">
                            <p className="mb-0 main-font fw-bold me-2">
                              5
                            </p>
                            <div
                              className="progress"
                              role="progressbar"
                              aria-label="Basic example"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              <div
                                className="progress-bar"
                                style={{ width: `${details.review_rate[5]}%` }}
                              ></div>
                            </div>
                          </div>
                          <div className="five-stars d-flex align-items-center my-3">
                            <p className="mb-0 main-font fw-bold me-2">
                              4 
                            </p>
                            <div
                              className="progress"
                              role="progressbar"
                              aria-label="Basic example"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              <div
                                className="progress-bar"
                                style={{ width: `${details.review_rate[4]}%` }}
                              ></div>
                            </div>
                          </div>
                          <div className="five-stars d-flex align-items-center my-3">
                            <p className="mb-0 main-font fw-bold me-2">
                              3 
                            </p>
                            <div
                              className="progress"
                              role="progressbar"
                              aria-label="Basic example"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              <div
                                className="progress-bar"
                                style={{ width: `${details.review_rate[3]}%` }}
                              ></div>
                            </div>
                          </div>
                          <div className="five-stars d-flex align-items-center my-3">
                            <p className="mb-0 main-font fw-bold me-2">
                              2 
                            </p>
                            <div
                              className="progress"
                              role="progressbar"
                              aria-label="Basic example"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              <div
                                className="progress-bar"
                                style={{ width: `${details.review_rate[2]}%` }}
                              ></div>
                            </div>
                          </div>
                          <div className="five-stars d-flex align-items-center my-3">
                            <p className="mb-0 main-font fw-bold me-2">
                              1 
                            </p>
                            <div
                              className="progress"
                              role="progressbar"
                              aria-label="Basic example"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              <div
                                className="progress-bar"
                                style={{ width: `${details.review_rate[1]}%` }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        <div className="viwe-all-btn py-4">
                          <button
                            className="main-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#addReviewModal2"
                          >
                            <p className="mb-0">{t('custom_text.addReview')}</p>
                          </button>
                        </div>
                        <div
                          className="modal fade"
                          id="addReviewModal2"
                          data-bs-keyboard="false"
                          tabIndex="-1"
                          aria-labelledby="staticBackdropLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                              <div className="modal-body text-center">
                                <h4 className="mb-0 main-font">
                                {t('custom_text.rate')}
                                </h4>
                                <Rating
                                  name="half-rating"
                                  value={rating} // controlled component
                                  precision={0.5}
                                  onChange={handleRatingChange}
                                />
                                <textarea
                                  className="w-100 p-4 add-review"
                                  placeholder={t('custom_text.opinion')}
                                  cols="30"
                                  rows="10"
                                  value={opinion} // controlled component
                                  onChange={handleOpinionChange}
                                ></textarea>
                                <div className="viwe-all-btn py-4">
                                  <button
                                    onClick={() =>
                                      addReview(
                                        details.Product_details.product_id,
                                        opinion,
                                        rating
                                      )
                                    }
                                    className="main-btn w-100"
                                  >
                                    <p className="mb-0">{t('custom_text.sendR')}</p>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-6">
                      <div className="row g-5">
                        {details.review.map((review, i) => (
                          <div key={i} className="col-lg-6">
                            <div className="review-header">
                              <div className="row align-items-center">
                                <div className="col-2">
                                  <div className="review-img">
                                    <Avatar
                                      alt={review.user_name}
                                      className="mx-auto"
                                      src={review1}
                                      sx={{ width: 60, height: 60 }}
                                    />
                                  </div>
                                </div>
                                <div className="col-10">
                                  <div className="review-user-name ms-3">
                                    <p className="mb-0 fw-bold main-font">
                                      {review.user_name}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="review-rating py-3">
                              <div className="d-flex align-items-center">
                                <div className="stars">
                                  <Rating
                                    name="half-rating-read"
                                    defaultValue={parseFloat(review.rate)}
                                    precision={0.5}
                                    readOnly
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="review-text">
                              <p className="mb-0">{review.comment}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </>
      )}
    </>
  );
}

export default ProductDetails;