import axios from "axios";
import baseURL from "../../assets/images/config";
import { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

export default function CartProducts({
  cart,
  remove,
  update,
  cartId,
  dis,
  itemsProduct,
  setItemProduct,
  gift,
}) {
  const { t } = useTranslation('global');
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("userToken");
  const [giftsProducts, setGiftsProducts] = useState([]);
  const [allSmells, setAllSmells] = useState([]);
  const [selectedGift, setSelectedGift] = useState();
  const [allGifts, setAllGifts] = useState([]);
  const [smellSelections, setSmellSelections] = useState([]);

  const handleChange = async (e) => {
    const { id, value } = e.target;
    const index = parseInt(id.split("_")[0].replace("select", "")) - 1;
    const smellIndex = parseInt(id.split("_")[1]) - 1;

    // Update the itemsProduct state
    setItemProduct((prev) => ({
      ...prev,
      [id]: value,
    }));

    // Update smellSelections state
    const updatedSelections = [...smellSelections];
    if (!updatedSelections[index]) {
      updatedSelections[index] = [];
    }
    updatedSelections[index][smellIndex] = value;
    setSmellSelections(updatedSelections);

    // Check if all dropdowns for this product are filled
    checkProductDropdownsSelected(index);

    // Update smell for the product
    const productId = cart[index].id;
    const smells = updatedSelections[index];
    await updateSmell(productId, smells);
  };

  const checkProductDropdownsSelected = (productIndex) => {
    const product = cart[productIndex];
    const productSelections = smellSelections[productIndex] || [];

    const allSelected =
      productSelections.length === product.product_qty &&
      productSelections.every((smell) => smell !== undefined && smell !== "");

    if (allSelected) {
      // Actions to take if all dropdowns are selected
    }
  };

  async function updateSmell(id, smells) {
    let { data } = await axios
      .post(
        `${baseURL}/add/smell/${id}`,
        {
          smell: smells,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch((err) => {
        // toast.error("Failed to update smell");
        return err;
      });
    if (data) {
      toast.loading("Updating smell...");
      toast.dismiss();
      setTimeout(() => {
        toast.success(data.message);
      }, 1000);
    }
  }

  useEffect(() => {
    let tempItemProduct = {};
    let tempSmellSelections = [];

    cart?.forEach((single, index) => {
      for (let i = 0; i < single.product_qty; i++) {
        tempItemProduct[`select${index + 1}_${i + 1}`] = "";
      }
      tempSmellSelections[index] = new Array(single.product_qty).fill("");
    });

    setItemProduct(tempItemProduct);
    setSmellSelections(tempSmellSelections);
  }, [cart]);

  const renderSmells = (qty, smells, index) => {
    let arr = [];

    for (let i = 0; i < qty; i++) {
      const item = (
        <div
          key={i}
          className="product-cart-info mt-3 d-flex align-items-center gap-2"
        >
          <p className="mb-0 mr-2">{t('custom_text.smell')}:</p>
          <p className="mb-0 ml-2">
            <select
              className="form-select"
              id={`select${index + 1}_${i + 1}`}
              onChange={(e) => handleChange(e)}
            >
              <option disabled selected>
                {t('custom_text.chooseS')}
              </option>
              {smells.map((singleSmell, idx) => (
                <option key={idx} value={singleSmell}>
                  {singleSmell}
                </option>
              ))}
            </select>
          </p>
          <p className="mb-0 ml-2">{t('custom_text.selectSmell')}</p>
        </div>
      );
      arr.push(item);
    }

    return arr;
  };

  async function setSelectedGiftFn({ product_id, qty, gift_id, gift_name }) {
    setSelectedGift({ product_id, qty, gift_id, gift_name });
  }

  const giftsInArr = (data) => {
    console.log("data: ", data);
    gift(data);
  };

  async function setGifts(product) {
    setGiftsProducts(product.giftproduct);
  }

  async function doneGift(CartId, product_id, qty, gift_id) {
    // toast.loading("Updating gift...");
    let { data } = await axios
      .post(
        `${baseURL}/changeSummaryCard/${CartId}`,
        {
          product_id: product_id,
          qty: qty,
          gift_id: gift_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .catch((err) => toast.error(err.response.data.message));

    if (data) {
      toast.dismiss();
      setTimeout(() => {
        // toast.success(data.message);
        setSelectedGift({
          ...selectedGift,
          status: true,
        });
      }, 1000);
    } else {
      toast.dismiss();
      setTimeout(() => {
        toast.error("Failed to update gift");
      }, 1000);
    }
  }

  const render = () => {
    let arr = [];
    cart?.forEach((single, i) => {
      const item = (
        <div key={i} className="row cart g-4 align-items-center">
          <div className="col-3">
            <div className="img">
              <img src={single?.product_image} className="w-100" />
            </div>
          </div>
          <div className="col-md-9">
            <div className="cart-details position-relative">
              <div className="product-cart-header d-flex align-items-center justify-content-between">
                <h5 className="product-cart-title">{single.product_name}</h5>
                <div className="product-cart-price">
                  <span
                    className="mx-2 main-font text-danger cursor-pointer"
                    onClick={(e) => remove(single.id, single.product_qty)}
                  >
                    {t('custom_text.remove')}
                  </span>
                  <h5>{single?.product_price} EGP</h5>
                  <h5 className="product-cart-discount">
                    {single.typesale_name}
                  </h5>
                </div>
              </div>
              <div className="product-cart-body">
                <div className="count mt-3 text-center d-flex align-items-center justify-content-between">
                  <i
                    className={`fa-solid fa-minus cursor-pointer ${dis ? `disabled` : ""
                      }`}
                    onClick={(e) =>
                      update(single.id, single.product_qty - 1, cartId)
                    }
                  />
                  <div className="count-num">{single?.product_qty}</div>
                  <i
                    className="fa-solid fa-plus cursor-pointer"
                    onClick={(e) =>
                      update(single.id, single.product_qty + 1, cartId)
                    }
                  />
                </div>
              </div>
              {renderSmells(single.product_qty, single?.product_smell, i)}
              {single.typesale_name?.toLowerCase() === "buy 2 get 1" &&
                single.product_qty === 2 && (
                  <>
                    <div className="viwe-all-btn py-4">
                      <button
                        data-bs-toggle="modal"
                        data-bs-target={`#chooseGift${single.id}`}
                        type="button"
                        className="main-btn"
                        onClick={() => {
                          setSelectedGiftFn({
                            product_id: single?.id,
                            qty: single?.product_qty,
                            gift_id: 0,
                          });
                          setGifts(single);
                        }}
                      >
                        <p className="mb-0">{t('custom_text.chooseG')}</p>
                      </button>
                    </div>
                    <div
                      className="modal fade"
                      id={`chooseGift${single.id}`}
                      data-bs-backdrop="static"
                      data-bs-keyboard="false"
                      tabIndex="-1"
                      aria-labelledby="staticBackdropLabel"
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h2 className="modal-title fs-5"> {t('custom_text.chooseG')}</h2>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <div className="modal-body">
                            <div
                              className="container h-4  overflow-auto"
                              style={{ height: "300px" }}
                            >
                              {single?.giftproduct?.map((gift) => (
                                <div className="row" key={gift.gift_id}>
                                  <div className="col-md-12 form-check  d-flex align-items-center justify-content-between ">
                                    <div>
                                      <input
                                        className="form-check-input"
                                        type="radio"
                                        name={`radio${single.id}`}
                                        id={gift.gift_id}
                                        value={gift.gift_id}
                                        onChange={() => {
                                          setAllGifts((prevAllGifts) => {
                                            doneGift(
                                              cartId,
                                              single?.id,
                                              single?.product_qty,
                                              gift?.gift_id
                                            )
                                            // Determine the maximum number of gifts based on typeSale
                                            let maxGifts = single.product_qty;
                                            if (
                                              single.typesale_name?.toLowerCase() ===
                                              "buy 2 get 1"
                                            ) {
                                              maxGifts = Math.floor(
                                                single.product_qty / 2
                                              );
                                            }

                                            // Filter out gifts already selected for this product
                                            let updatedGifts =
                                              prevAllGifts.filter(
                                                (g) =>
                                                  g.product_id !== single.id
                                              );

                                            // If the gift is already selected, replace it
                                            const existingGiftIndex =
                                              updatedGifts.findIndex(
                                                (g) =>
                                                  g.gift_id === gift.gift_id &&
                                                  g.product_id === single.id
                                              );

                                            if (existingGiftIndex !== -1) {
                                              // Replace the existing gift if it's already selected
                                              updatedGifts[existingGiftIndex] =
                                              {
                                                ...gift,
                                                product_id: single.id,
                                                qty: single.product_qty,
                                              };
                                            } else {
                                              // If the gift is not yet added and the limit is not exceeded, add it
                                              if (
                                                updatedGifts.filter(
                                                  (g) =>
                                                    g.product_id === single.id
                                                ).length < maxGifts
                                              ) {
                                                updatedGifts.push({
                                                  ...gift,
                                                  product_id: single.id,
                                                  qty: single.product_qty,
                                                });
                                              }
                                            }

                                            giftsInArr(updatedGifts);
                                            return updatedGifts;
                                          });

                                          setSelectedGiftFn({
                                            ...selectedGift,
                                            product_id: single.id,
                                            gift_id: gift.gift_id,
                                            gift_name: gift.gift_name,
                                            qty: single.product_qty,
                                          });
                                        }}
                                      />

                                      <label
                                        className="form-check-label mx-4"
                                        htmlFor={gift.gift_id}
                                      >
                                        {gift.gift_name}
                                      </label>
                                    </div>
                                    <img
                                      src={gift.gift_image}
                                      width="80"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="modal-footer">
                            <button
                              type="button"
                              className="btn btn-secondary"
                              data-bs-dismiss="modal"
                            >
                              {t('custom_text.close')}
                            </button>
                            <button
                              type="button"
                              className="btn btn-success"
                              data-bs-dismiss="modal"
                            >
                              <p className="mb-0">{t('custom_text.addG')}</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
            </div>
          </div>
        </div>
      );
      arr.push(item);
    });
    return arr;
  };

  return <div className="row">{render()}</div>;
}
