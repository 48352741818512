import React, { useContext, useEffect, useState } from 'react';
import Navbar from '../Navbar/Navbar';
import { Outlet } from 'react-router-dom';
import Footer from '../Footer/Footer';
import { cartCountContext } from '../Context/cartCountContext';
import { CartContext } from '../Context/CartContext';
import { TranslateContext } from '../Context/TranslateContext';


function Layout() {
  let{setCartCount} = useContext(cartCountContext)
  const { arLang } = useContext(TranslateContext);
  let { showCart } = useContext(CartContext);
  async function showCartItems() {
    let { data } = await showCart();
    if (data) {
      setCartCount(data.data.card_count)
    }
  }
  useEffect(()=>{
    showCartItems()
  },[])
  return <>
    <div dir={`${arLang ? "rtl" : 'ltr'}`}>
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  </>
}

export default Layout;