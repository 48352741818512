import { createContext, useState } from "react";
import Info from "../Info/Info.jsx";
import Address from "../Address/Address.jsx";
import Shipping from "../Shipping/Shipping.jsx";
import Payment from "../Payment/Payment.jsx";

export let PaymentContext = createContext();

export default function PaymentContextProvider(props) {
    let [userName, setUserName] = useState(null);
    let [userPhone, setUserPhone] = useState(null);
    let [userAddress, setUserAddress] = useState(null);
    let [userShipping, setUserShipping] = useState(null);
    let [userDate, setUserDate] = useState(null);
    let [cartPoints, setCartPoints] = useState(0);
    let [cartId, setCartId] = useState(null);
    return <PaymentContext.Provider value={{ userName, setUserName, userPhone, setUserPhone, userAddress, setUserAddress, userShipping, setUserShipping, userDate, setUserDate, cartId , setCartId, cartPoints, setCartPoints }}>
            {props.children}
    </PaymentContext.Provider>
}