import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Outlet } from 'react-router-dom';

function PaymentSession() {
  const {t} = useTranslation('global')
  return <>
    <section className="py-3">
      <div className="container">
        <ul className="payment-session list-unstyled d-flex align-items-center mx-lg-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <NavLink className="my-lg-0 my-2 nav-link mx-3 cursor-pointer d-inline-block" aria-current="page" to={"info"}>{t('navlinks.info')}</NavLink>
            <i className="fa-solid fa-chevron-right"></i>
          </li>
          <li className="nav-item">
            <NavLink className="my-lg-0 my-2 nav-link mx-3 cursor-pointer d-inline-block" to={"address"}>{t('navlinks.address')}</NavLink>
            <i className="fa-solid fa-chevron-right"></i>
          </li>
          <li className="nav-item">
            <NavLink className="my-lg-0 my-2 nav-link mx-3 cursor-pointer d-inline-block" to={"shipping"}>{t('navlinks.shipping')}</NavLink>
            <i className="fa-solid fa-chevron-right"></i>
          </li>
          <li className="nav-item">
            <NavLink className="my-lg-0 my-2 nav-link mx-3 cursor-pointer d-inline-block" to={"payment"}>{t('navlinks.payment')}</NavLink>
          </li>
        </ul>
      </div>
    </section>
    <section className='py-5'>
        <div className="container">
          <Outlet></Outlet>
        </div>
    </section>
  </>
}

export default PaymentSession;