import React, { useContext } from 'react';
import { PaymentContext } from '../Context/PaymentContext';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';


function Shipping() {
  const {t} = useTranslation('global')
  let { userShipping, setUserShipping, userDate, setUserDate } = useContext(PaymentContext);
  let navigate = useNavigate();
  function checkShipping() {
    if (userShipping == null) {
      toast.error('Please select shipping method')
    } else if (userShipping === 'free') {
      navigate('/payment-session/payment');
    } else if (userShipping === 'date') {
      if (userDate == null) {
        toast.error('if you choose Schedule method you must select date')
      } else {
        navigate('/payment-session/payment');
      }
    }
  }
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Shipping type</title>
      </Helmet>
      <section className="min-vh-100">
        <div className="row">
          <div className="col-lg-7 col-md-10">
            <table className="table shipping table-bordered">
              <tbody>
                <tr>
                  <td>
                    <div className="regular d-flex align-items-center justify-content-between">
                      <div className="form d-flex align-item-cetner">
                        <input
                          type="radio"
                          value="free"
                          id="free"
                          onChange={(e) => {
                            setUserShipping(e.target.value);
                          }}
                          className="me-2"
                          name="shipping"
                        />
                        <label htmlFor="free" className="mb-0 me-3">
                        {t('custom_text.free')}
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="regular d-flex align-items-center justify-content-between">
                      <div className="form d-flex align-item-cetner">
                        <input
                          type="radio"
                          value="date"
                          onChange={(e) => {
                            setUserShipping(e.target.value);
                          }}
                          className="me-2"
                          name="shipping"
                          id="schedule"
                        />
                        <label htmlFor="schedule" className="mb-0 me-3">
                        {t('custom_text.schedule')}{" "}
                        </label>
                        <p className="mb-0 mx-1">
                        {t('custom_text.schText')}
                        </p>
                      </div>
                      <div className="date">
                        <input
                          type="date"
                          className="form-control"
                          onChange={(e) => {
                            setUserDate(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="viwe-all-btn pt-5 mt-5 text-end">
          <button onClick={checkShipping} className="main-btn">
            <p className="mb-0">{t('custom_text.next')}</p>
          </button>
        </div>
      </section>
    </>
  );
}

export default Shipping;