import React, { useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PaymentContext } from '../Context/PaymentContext';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';


function Info() {
  const {t} = useTranslation('global')
  const { setUserName, setUserPhone } = useContext(PaymentContext);

  let navigate = useNavigate()
  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('errors.required')).matches(/^[a-zA-Z\u0600-\u06FF]{3,}(?:\s[a-zA-Z\u0600-\u06FF]{3,})+$/, t('errors.names2')),
    phone: Yup.string().required(t('errors.required')).matches(/^(0\d{10}|\d{11})$/, t('errors.names2')),
  });
  function submitInfo(values) {
    setUserName(values.name);
    setUserPhone(values.phone);
    navigate('/payment-session/address')
  }
  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
    },
    validationSchema: validationSchema,
    onSubmit: submitInfo
  });

  return (
    <section className='loading'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Your Info</title>
      </Helmet>
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-10 col-12">
            <div className="user-info p-4">
              <p className='main-color main-font pb-4'>{t('custom_text.yourInfo')}</p>
              <form onSubmit={formik.handleSubmit}>
                <div className="row g-4">
                  <div className="col-6">
                    <input type="text" placeholder={t('placeholders.name')} className='w-100 p-3 sign-text' {...formik.getFieldProps('name')} />
                    {formik.touched.name && formik.errors.name ? (
                      <p className="text-danger">{formik.errors.name}</p>
                    ) : null}
                  </div>
                  <div className="col-6">
                    <input type="text" placeholder={t('placeholders.phonenumber')} className='w-100 p-3 sign-text' {...formik.getFieldProps('phone')} />
                    {formik.touched.phone && formik.errors.phone ? (
                      <p className="text-danger">{formik.errors.phone}</p>
                    ) : null}
                  </div>
                </div>
                <div className="viwe-all-btn py-4">
                  <button type="submit" className='main-btn'><p className='mb-0'>{t('custom_text.confirm')}</p></button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Info;
