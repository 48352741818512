import ProutectedRoute from './Components/ProutectedRoute/ProutectedRoute.jsx';
import './App.css';
import React, { Suspense, useContext, useEffect } from 'react';
import Layout from './Components/Layout/Layout.jsx';
import { UesrContext } from './Components/Context/UserContext.js';
import CartContextProvider from './Components/Context/CartContext.js';
import WishlistContextProvider from './Components/Context/WishlistContext.js';
import PaymentContextProvider from './Components/Context/PaymentContext.js';
import ButtonContextProvider from './Components/Context/ButtonContext.js';
import PaymentProutectedRoute from './Components/PaymentProutectedRoute/PaymentProutectedRoute.jsx';
import InversProtectedRoute from './Components/InversProtectedRoute/InversProtectedRoute.jsx';
import Loading from './Components/Loading/Loading.jsx';
import Address from './Components/Address/Address.jsx';
import Shipping from './Components/Shipping/Shipping.jsx';
import Info from './Components/Info/Info.jsx';
import Payment from './Components/Payment/Payment.jsx';
import ProductDetails from './Components/ProductDetails/ProductDetails.jsx';
import PaymentSession from './Components/PaymentSession/PaymentSession.jsx';
import CartCountContextProvider from './Components/Context/cartCountContext.js';
import { gapi } from 'gapi-script';
import { RouterProvider, createBrowserRouter, createHashRouter } from 'react-router-dom';
import CartPage from './Components/cartPage/index.jsx';
import TranslateProvider from './Components/Context/TranslateContext.jsx';
import { LanguageProvider } from './Components/Context/LanguageContext.js';
const Home = React.lazy(() => import('./Components/Home/Home.jsx'));
const Cart = React.lazy(() => import('./Components/Cart/Cart.jsx'));
const Wishlist = React.lazy(() => import('./Components/Wishlist/Wishlist.jsx'));
const Products = React.lazy(() => import('./Components/Products/Products.jsx'));
const Offers = React.lazy(() => import('./Components/Offers/Offers.jsx'));
const BestSeller = React.lazy(() => import('./Components/BestSeller/BestSeller.jsx'));
const MyAccount = React.lazy(() => import('./Components/MyAccount/MyAccount.jsx'));
const AboutUs = React.lazy(() => import('./Components/AboutUs/AboutUs.jsx'));
const ContactUs = React.lazy(() => import('./Components/ContactUs/ContactUs.jsx'));
const Login = React.lazy(() => import('./Components/Login/Login.jsx'));
const Register = React.lazy(() => import('./Components/Register/Register.jsx'));
const UserAddress = React.lazy(() => import('./Components/UserAddress/UserAddress.jsx'));
const Categories = React.lazy(() => import('./Components/Categories/Categories.jsx'));
const OrderIsDone = React.lazy(() => import('./Components/OrderIsDone/OrderIsDone.jsx'));
const MyOrders = React.lazy(() => import('./Components/MyOrders/MyOrders.jsx'));
const TrackOrder = React.lazy(() => import('./Components/TrackOrder/TrackOrder.jsx'));
const TrackAllOrders = React.lazy(() => import('./Components/TrackAllOrders/TrackAllOrders.jsx'));

let Routers = createBrowserRouter([{
  path: '', element: <Layout />, children: [
    { index: true, element: <Suspense fallback={<Loading />}><Home /></Suspense> },
    { path: 'cart', element: <Suspense fallback={<Loading />}><CartPage /></Suspense> },
    { path: 'carts', element: <Suspense fallback={<Loading />}><Cart /></Suspense> },
    { path: 'wishlist', element: <Suspense fallback={<Loading />}><Wishlist /></Suspense> },
    { path: 'myAccount', element: <Suspense fallback={<Loading />}><ProutectedRoute><MyAccount /></ProutectedRoute></Suspense> },
    { path: 'products', element: <Suspense fallback={<Loading />}><Products /></Suspense> },
    { path: 'products/product-details/:id', element: <ProductDetails /> },
    { path: 'products/category/:id', element: <Suspense fallback={<Loading />}><Categories /></Suspense> },
    { path: 'order-is-done', element: <Suspense fallback={<Loading />}><OrderIsDone /></Suspense> },
    { path: 'offers', element: <Suspense fallback={<Loading />}><Offers /></Suspense> },
    { path: 'address', element: <Suspense fallback={<Loading />}><ProutectedRoute><UserAddress /></ProutectedRoute></Suspense> },
    { path: 'about-us', element: <Suspense fallback={<Loading />}><AboutUs /></Suspense> },
    { path: 'sign-in', element: <Suspense fallback={<Loading />}><InversProtectedRoute><Login /></InversProtectedRoute></Suspense> },
    { path: 'sign-up', element: <Suspense fallback={<Loading />}><InversProtectedRoute><Register /></InversProtectedRoute></Suspense> },
    { path: 'contact-us', element: <Suspense fallback={<Loading />}><ContactUs /></Suspense> },
    { path: 'bestSeller', element: <Suspense fallback={<Loading />}><BestSeller /></Suspense> },
    { path: 'myOrders', element: <Suspense fallback={<Loading />}><ProutectedRoute><MyOrders /></ProutectedRoute></Suspense> },
    { path: 'trackOrders/:id/:num', element: <Suspense fallback={<Loading />}><ProutectedRoute><TrackOrder /></ProutectedRoute></Suspense> },
    { path: 'trackAllOrders', element: <Suspense fallback={<Loading />}><ProutectedRoute><TrackAllOrders /></ProutectedRoute></Suspense> },
    { path: '*', element: <Home /> },
    {
      path: 'payment-session', element: <PaymentProutectedRoute><PaymentSession /></PaymentProutectedRoute>, children: [
        { path: 'info', element: <Info /> },
        { path: 'address', element: <Address /> },
        { path: 'shipping', element: <Shipping /> },
        { path: 'payment', element: <Payment /> },
      ]
    },
  ]
}])
function App() {
  let { setUserToken } = useContext(UesrContext);
  if (localStorage.getItem('userToken')) {
    setUserToken(localStorage.getItem('userToken'));
  }
  useEffect(() => {
    localStorage.setItem('lang',"en")
    function start() {
      gapi.client.init({
        clientId: "190398597229-pksro7v966b8hr1m13i0a5qgc5hvvjvo.apps.googleusercontent.com",
        scope: ""
      })
    }
    gapi.load('client:auth2', start)
  }, [])
  return <>
  <LanguageProvider>
    <TranslateProvider>
      <CartCountContextProvider >
        <ButtonContextProvider>
          <PaymentContextProvider>
            <WishlistContextProvider>
              <CartContextProvider>
                <RouterProvider router={Routers}></RouterProvider>
              </CartContextProvider>
            </WishlistContextProvider>
          </PaymentContextProvider>
        </ButtonContextProvider >
      </CartCountContextProvider>
    </TranslateProvider>
  </LanguageProvider>


  </>

}

export default App;
