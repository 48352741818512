import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import React, { useContext } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useQuery } from 'react-query';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import baseURL from '../../assets/images/config';
import logo from '../../assets/images/ramshea (1).png';
import { cartCountContext } from '../Context/cartCountContext';
import { UesrContext } from '../Context/UserContext';
import { useTranslation } from 'react-i18next';
import { TranslateContext } from '../Context/TranslateContext';
import { LanguageContext } from '../Context/LanguageContext';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));
function Navbar() {
  const { changeLanguage } = useContext(LanguageContext);
  const { handelChangeLang, arLang } = useContext(TranslateContext);
  let { userToken, setUserToken } = useContext(UesrContext);
  let { cartCount, setCartCount } = useContext(cartCountContext)
  const { t } = useTranslation('global');

  let navigate = useNavigate()
  const token = localStorage.getItem('userToken');
  async function logOut() {
    localStorage.removeItem('userToken');
    setCartCount('0')
    setUserToken(null);
    try {
      const { data } = await axios.get(`${baseURL}/user/logout`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      toast.success(data.message);
      navigate('/sign-in')

    } catch (error) {
      //   console.error('Error:', error);
    }
  }
  async function getHomeProducts() {
    return await axios.get(`${baseURL}/home`, {
      headers: {
        lang: localStorage.getItem('lang')
      }
    });
  }
  let { data, isLoading } = useQuery('home', getHomeProducts);


  return <>
    <Toaster />
    <div dir='ltr'>
      <div className="running-text d-flex overflow-hidden py-3">
        <p className='mx-5 mb-0 text-white'>{data?.data.data.Data_home_page.message_nav}</p>
        <p className='mx-5 mb-0 text-white'>{data?.data.data.Data_home_page.message_nav}</p>
        <p className='mx-5 mb-0 text-white'>{data?.data.data.Data_home_page.message_nav}</p>
        <p className='mx-5 mb-0 text-white'>{data?.data.data.Data_home_page.message_nav}</p>
        <p className='mx-5 mb-0 text-white'>{data?.data.data.Data_home_page.message_nav}</p>
        <p className='mx-5 mb-0 text-white'>{data?.data.data.Data_home_page.message_nav}</p>
        <p className='mx-5 mb-0 text-white'>{data?.data.data.Data_home_page.message_nav}</p>
        <p className='mx-5 mb-0 text-white'>{data?.data.data.Data_home_page.message_nav}</p>
        <p className='mx-5 mb-0 text-white'>{data?.data.data.Data_home_page.message_nav}</p>
        <p className='mx-5 mb-0 text-white'>{data?.data.data.Data_home_page.message_nav}</p>
        <p className='mx-5 mb-0 text-white'>{data?.data.data.Data_home_page.message_nav}</p>
        <p className='mx-5 mb-0 text-white'>{data?.data.data.Data_home_page.message_nav}</p>
        <p className='mx-5 mb-0 text-white'>{data?.data.data.Data_home_page.message_nav}</p>
        <p className='mx-5 mb-0 text-white'>{data?.data.data.Data_home_page.message_nav}</p>
        <p className='mx-5 mb-0 text-white'>{data?.data.data.Data_home_page.message_nav}</p>
        <p className='mx-5 mb-0 text-white'>{data?.data.data.Data_home_page.message_nav}</p>
        <p className='mx-5 mb-0 text-white'>{data?.data.data.Data_home_page.message_nav}</p>
        <p className='mx-5 mb-0 text-white'>{data?.data.data.Data_home_page.message_nav}</p>
        <p className='mx-5 mb-0 text-white'>{data?.data.data.Data_home_page.message_nav}</p>
        <p className='mx-5 mb-0 text-white'>{data?.data.data.Data_home_page.message_nav}</p>
        <p className='mx-5 mb-0 text-white'>{data?.data.data.Data_home_page.message_nav}</p>
        <p className='mx-5 mb-0 text-white'>{data?.data.data.Data_home_page.message_nav}</p>
        <p className='mx-5 mb-0 text-white'>{data?.data.data.Data_home_page.message_nav}</p>
      </div>
    </div >



    <nav className="navbar sticky-top navbar-expand-lg bg-body-tertiary">
      <div className="container-fluid">
        <Link className="navbar-brand" to={"/"}>
          <img src={logo} alt="Remshia" className='logo-size' />
        </Link>
        <div className='d-flex align-items-center order-lg-1'>

          <ul className='nav-icons mx-2 d-flex dropdown d-flex align-items-center justify-content-evenly my-0 list-unstyled'>
            <li className='mx-2'>
              {arLang ? (
                <span
                  onClick={() => {
                    handelChangeLang("en"); // Separate statements with a semicolon
                    changeLanguage('en');
                  }}
                  className='main-color fw-bold cursor-pointer'>
                  English
                </span>
              ) : (
                <span
                  onClick={() => {
                    handelChangeLang("ar");
                    changeLanguage('ar');
                  }}
                  className='main-color fw-bold cursor-pointer'>
                  العربية
                </span>
              )}
            </li>
            <li className='mx-2'>
              <i className="fa-regular cursor-pointer fa-user dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false"></i>
              <ul className="dropdown-menu bg-white">
                {userToken != null ? <>
                  <li><Link className="dropdown-item fs-6" to={'/myAccount'}>{t('navlinks.profile')}</Link></li>
                  <hr className='my-1' />
                  <li><Link className="dropdown-item fs-6" to={'/myOrders'}>{t('navlinks.myOrders')}</Link></li>
                  <hr className='my-1' />
                  <li><Link className="dropdown-item fs-6" to={'/address'}>{t('navlinks.addAddress')}</Link></li>
                  <hr className='my-1' />
                  <li><span className="dropdown-item fs-6 cursor-pointer" onClick={logOut}>{t('navlinks.LogOut')}</span></li>
                </>
                  : <>
                    <li><Link className="dropdown-item fs-6" to={'/sign-in'}>{t('navlinks.login')}</Link></li>
                    <hr className='my-1' />
                    <li><Link className="dropdown-item fs-6" to={'/sign-up'}>{t('navlinks.signup')}</Link></li>
                  </>
                }
              </ul>
            </li>
            <li className='mx-2 '>
              <Link to={'/wishlist'}>
                <i className="fa-regular cursor-pointer fa-heart"></i>
              </Link>
            </li>
            <li className='mx-2 '>
              <Link to={'/cart'}>
                <IconButton className='p-0' aria-label="cart">
                  <StyledBadge badgeContent={cartCount} color="success">
                    <ShoppingCartIcon />
                  </StyledBadge>
                </IconButton>
              </Link>
            </li>
          </ul>
          <button className="navbar-toggler border-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>
        </div>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-lg-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink className="my-lg-0 my-2 nav-link mx-2 cursor-pointer d-inline-block" activeclassname="active" aria-current="page" to={"/"}>{t('navlinks.home')}</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="my-lg-0 my-2 nav-link mx-2 cursor-pointer d-inline-block" to={"/products"}>{t('navlinks.products')}</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="my-lg-0 my-2 nav-link mx-2 cursor-pointer d-inline-block" to={"/offers"}>{t('navlinks.offers')}</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="my-lg-0 my-2 nav-link mx-2 cursor-pointer d-inline-block" to={"/bestSeller"}>{t('navlinks.bestSeller')}</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="my-lg-0 my-2 nav-link mx-2 cursor-pointer d-inline-block" to={"/about-us"}>{t('navlinks.about_us')}</NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="my-lg-0 my-2 nav-link mx-2 cursor-pointer d-inline-block" to={"/contact-us"}>{t('navlinks.contact_us')}</NavLink>
            </li>

          </ul>
        </div>

      </div>
    </nav>
  </>
}

export default Navbar;