import React from 'react';
import remshia from '../../assets/images/ramshea (1).png'
function Loading() {
  return <>
    <section className='loading text-center d-flex align-items-center justify-content-center'>
      <div>
        <div>
          <img src={remshia} alt="logo" />
        </div>
        <div className="loader mx-auto mt-4"></div>
      </div>
    </section>
  </>
}

export default Loading;